import React from "react"
import {
  useTheme,
  Container,
  Divider,
  Flex,
  Box,
  Text,
  Link,
} from "@chakra-ui/react"
import { Link as LinkGatsby } from "gatsby"
import { VERSION_APP, VERSION_API } from "../../const/versions"
import format from "date-fns/format"
import Bowser from "bowser"

let browser = {}
// Wrap the require in check for window
if (typeof window !== `undefined`) {
  const bowser = require("bowser")
  browser = bowser.getParser(window.navigator.userAgent).getBrowser()
} else {
  browser.name = "undefined"
  browser.version = ""
}

const PageFooter = ({ dateFormatted }) => {
  // ==========================================================================
  // ==========================================================================
  return (
    <Container px={4} minH="200px">
      <Divider mt={16} />
      <Flex justify="space-between" wrap="wrap">
        <Flex direction="column">
          <Text>© Prof. Dr. Ulrich Anders</Text>
          <Text>prof.anders@online.de</Text>
          <Link as={LinkGatsby} to="/">
            https://project-status.org
          </Link>
          <Link
            href="https://creativecommons.org/licenses/by-nd/4.0/"
            isExternal
          >
            License: CC BY-ND 4.0
          </Link>
          <Link href="https://app.project-status.org/factory-reset" isExternal>
            Factory Reset
          </Link>
        </Flex>

        <Box>
          <Text>
            Version: {VERSION_APP} | API {VERSION_API}
          </Text>
          <Text>Last change: {dateFormatted}</Text>
          <Text>
            Browser: {browser.name} {browser.version}
          </Text>
          <Flex>
            <Link as={LinkGatsby} to="/imprint">
              Imprint
            </Link>
            <Text ml={2}>·</Text>
            <Link as={LinkGatsby} to="/data-protection" ml={2}>
              Data Protection
            </Link>
          </Flex>
        </Box>
      </Flex>
    </Container>
  )
}

export default PageFooter
