import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import { MDXProvider } from "@mdx-js/react"
import CookieConsent, { Cookies } from "react-cookie-consent"
import { Container } from "@chakra-ui/react"
import components from "../../theme/components"
// import { HelmetProvider } from "react-helmet-async"

import PageHeader from "../PageHeader"
import PageFooter from "../PageFooter"
import "@fontsource/fira-sans/400.css"
import "@fontsource/fira-sans/500.css"
import "@fontsource/fira-sans/600.css"
import "@fontsource/fira-sans/700.css"
import "@fontsource/fira-sans/400-italic.css"
import "@fontsource/fira-sans/500-italic.css"
import "@fontsource/fira-sans/600-italic.css"
import "@fontsource/fira-sans/700-italic.css"
import "./layout.css"

const Layout = ({ children }) => {
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
              dateFormatted
            }
          }
        }
      `}
      render={(data) => (
        <Container>
          {/* <HelmetProvider> */}
          <MDXProvider components={components}>
            <PageHeader siteTitle={data.site.siteMetadata.title} />
            {children}
            <PageFooter dateFormatted={data.site.siteMetadata.dateFormatted} />
            <CookieConsent
              buttonText="OK"
              cookieName="projectStatusOrg"
              sameSite="strict"
              cookieSecurity={true}
              debug={false}
              buttonStyle={{ background: "#ffeb3b", fontSize: "13px" }}
            >
              For proper functioning this website uses only essential cookies.
              By using this website you give your consent to these cookies.
            </CookieConsent>
          </MDXProvider>
          {/* </HelmetProvider> */}
        </Container>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
