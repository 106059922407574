// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-mdx": () => import("./../../../src/pages/about.mdx" /* webpackChunkName: "component---src-pages-about-mdx" */),
  "component---src-pages-changelog-mdx": () => import("./../../../src/pages/changelog.mdx" /* webpackChunkName: "component---src-pages-changelog-mdx" */),
  "component---src-pages-concepts-js": () => import("./../../../src/pages/concepts.js" /* webpackChunkName: "component---src-pages-concepts-js" */),
  "component---src-pages-data-protection-mdx": () => import("./../../../src/pages/data-protection.mdx" /* webpackChunkName: "component---src-pages-data-protection-mdx" */),
  "component---src-pages-imprint-mdx": () => import("./../../../src/pages/imprint.mdx" /* webpackChunkName: "component---src-pages-imprint-mdx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-infos-mdx": () => import("./../../../src/pages/infos.mdx" /* webpackChunkName: "component---src-pages-infos-mdx" */),
  "component---src-pages-roadmap-mdx": () => import("./../../../src/pages/roadmap.mdx" /* webpackChunkName: "component---src-pages-roadmap-mdx" */),
  "component---src-templates-template-concepts-js": () => import("./../../../src/templates/template-concepts.js" /* webpackChunkName: "component---src-templates-template-concepts-js" */)
}

