import React from "react"
import { Link as LinkGatsby } from "gatsby"
import * as chakraComponents from "@chakra-ui/react"
import {
  chakra,
  Box,
  Heading,
  Text,
  UnorderedList,
  OrderedList,
  ListItem,
  Link,
} from "@chakra-ui/react"
import H from "../componentsWithChakra/H"

const componentsRemapped = {
  h1: (props) => <Box as="h1" textStyle="h2" mt={8} mb={4} {...props} />,
  h2: (props) => <Box as="h2" textStyle="h3" mt={4} mb={2} {...props} />,
  h3: (props) => <Box as="h3" textStyle="h4" mt={4} mb={2} {...props} />,
  h4: (props) => <Box as="h4" textStyle="h5" mt={4} mb={1} {...props} />,
  h5: (props) => <Box as="h5" textStyle="h5" mt={4} mb={1} {...props} />,
  h6: (props) => <Box as="h6" textStyle="h6" mt={4} mb={1} {...props} />,
  p: (props) => <Text mb={2} {...props} />,
  ul: (props) => <UnorderedList pl={2} mb={2} {...props} />,
  ol: (props) => <OrderedList pl={2} mb={2} {...props} />,
  li: (props) => <ListItem {...props} />,
}

// a: (props) => <chakra.a {...props} />,

const components = { ...chakraComponents, ...componentsRemapped }

export default components

// example
// https://github.com/chakra-ui/chakra-ui/blob/develop/website/src/components/mdx-components.tsx

// const MDXComponents = {
//   h1: (props) => <chakra.h1 apply="mdx.h1" {...props} />,
//   h2: (props) => <LinkedHeading apply="mdx.h2" {...props} />,
//   h3: (props) => <LinkedHeading as="h3" apply="mdx.h3" {...props} />,
//   h4: (props) => <LinkedHeading as="h4" apply="mdx.h4" {...props} />,
//   hr: (props) => <chakra.hr apply="mdx.hr" {...props} />,
//   strong: (props) => <Box as="strong" fontWeight="semibold" {...props} />,
//   inlineCode: InlineCode,
//   code: CodeBlock,
//   pre: Pre,
//   kbd: Kbd,
//   br: (props) => <Box height="24px" {...props} />,
//   table: Table,
//   th: THead,
//   td: TData,
//   a: (props) => <chakra.a apply="mdx.a" {...props} />,
//   p: (props) => <chakra.p apply="mdx.p" {...props} />,
//   ul: (props) => <chakra.ul apply="mdx.ul" {...props} />,
//   ol: (props) => <chakra.ol apply="mdx.ul" {...props} />,
//   li: (props) => <chakra.li pb="4px" {...props} />,
//   blockquote: (props) => (
//     <Alert
//       mt="4"
//       role="none"
//       status="warning"
//       variant="left-accent"
//       as="blockquote"
//       rounded="4px"
//       my="1.5rem"
//       {...props}
//     />
//   ),
//   "carbon-ad": CarbonAd,
//   ComponentLinks,
//   IconsList,
//   PropsTable,
// }
