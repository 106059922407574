import { Link } from "gatsby";
import Layout from "../../../../src/components/Layout";
import SEO from "../../../../src/components/SEO";
import { Container } from "@chakra-ui/react";
import * as React from 'react';
export default {
  Link,
  Layout,
  SEO,
  Container,
  React
};