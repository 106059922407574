const styles = {
  global: (props) => ({
    "*": {
      boxSizing: "border-box",
      fontWeight: "medium",
      fontSize: "md",
    },
    body: {},
    a: { color: "blue.800", _hover: { color: "blue.600" } },
    ".react-icons": {
      position: "relative",
      top: "5px",
    },
    // ".anchor": {
    //   marginLeft: "-25px",
    //   marginRight: "5px",
    //   position: "relative",
    //   scrollMarginTop: "100px",
    //   opacity: "0.33",
    //   display: "inline-block",
    //   width: "20px",
    //   _hover: { opacity: 1 },
    // },
  }),
}

export default styles
