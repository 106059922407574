import React from "react"
import { ChakraProvider, CSSReset, GlobalStyle } from "@chakra-ui/react"
import theme from "./src/theme"

export const wrapRootElement = ({ element }) => {
  return (
    <ChakraProvider resetCss={true} theme={theme}>
      {element}
    </ChakraProvider>
  )
}
