import { Link } from "gatsby";
import Layout from "../../../../src/components/Layout";
import SEO from "../../../../src/components/SEO";
import { chakra, Container } from "@chakra-ui/react";
import { BiBlock, BiCaretRight, BiCaretLeft } from "react-icons/bi";
import { FaSortNumericDown, FaSortAlphaDown } from "react-icons/fa";
import { MdSubdirectoryArrowRight } from "react-icons/md";
import { IoHourglassOutline } from "react-icons/io5";
import * as React from 'react';
export default {
  Link,
  Layout,
  SEO,
  chakra,
  Container,
  BiBlock,
  BiCaretRight,
  BiCaretLeft,
  FaSortNumericDown,
  FaSortAlphaDown,
  MdSubdirectoryArrowRight,
  IoHourglassOutline,
  React
};