import React from "react"
import { Link as LinkGatsby } from "gatsby"
import PropTypes from "prop-types"
import {
  Img,
  Container,
  Divider,
  Flex,
  Heading,
  Text,
  Link,
} from "@chakra-ui/react"
import pcoLogo from "../../img/svg/logo/pcoLogo1.svg"
import { META_URL_SITE, META_APP_SITE } from "../../const/urlGatsby.js"

const PageHeader = ({ siteTitle }) => (
  <Container px={4}>
    <Flex justify="space-between" py={1}>
      <Flex flex="0 0 auto">
        <Img src={pcoLogo} h="62px" pt={2} flex="0 0 auto" mr={2} alt="" />
        <Link
          as={LinkGatsby}
          to="/"
          _hover={{ color: "grey.800" }}
          flex="0 0 auto"
        >
          <Heading fontWeight="semibold" flex="0 0 auto">
            THE PROJECT STATUS{" "}
          </Heading>
          <Text flex="0 0 auto">For Agile & Classical Project Management</Text>
        </Link>
      </Flex>

      <Text
        fontSize="lg"
        color="blueGrey.400"
        flex="1 1 auto"
        ml={4}
        textAlign="right"
        display={["none", "inline-block"]}
      >
        "Project Management without status reports is like an aeroplane without
        instruments. You would basically fly blind."
      </Text>
    </Flex>

    <Flex justify="space-between" mb={2} wrap="wrap">
      <Text ml="35px">{META_URL_SITE} ★★★★★</Text>
      <Flex align="baseline" justify="flex-end" flex="1 1 auto" wrap="wrap">
        <Link href={META_APP_SITE + "/"} ml={4} isExternal>
          Status Sheet
        </Link>
        <Link href={META_APP_SITE + "/progress"} ml={4} isExternal>
          Progress
        </Link>
        <Link href={META_APP_SITE + "/persons"} ml={4} isExternal>
          Persons
        </Link>
        <Link href={META_APP_SITE + "/setup"} ml={4} isExternal>
          Setup
        </Link>
        <Text ml={2}>|</Text>
        <Link href={META_APP_SITE + "/score"} ml={2} isExternal>
          Score
        </Link>
        <Text ml={2}>|</Text>
        <Link as={LinkGatsby} to="/" ml={2}>
          Start
        </Link>
        <Link as={LinkGatsby} to="/infos" ml={4}>
          Infos
        </Link>
        <Link as={LinkGatsby} to="/concepts" ml={4}>
          Concepts
        </Link>
        <Link as={LinkGatsby} to="/about" ml={4}>
          About
        </Link>
      </Flex>
    </Flex>
    <Divider mb={4} />
  </Container>
)

PageHeader.propTypes = {
  siteTitle: PropTypes.string,
}

PageHeader.defaultProps = {
  siteTitle: "",
}

export default PageHeader
